import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// @ts-ignore
import Logo from '../../assets/logo.png';
import { Stack} from "@mui/material";
import {useQuery} from "react-query";
import {getTownsList} from "../../api/cities/cities";
import Container from '@mui/material/Container';
import Basket from "../Basket/Basket";
import {Link, NavLink, useLocation} from "react-router-dom";
import {saveContextToLocalStorage, useTownContext} from "../../context/TownsContext";
import {useEffect, useState} from "react";
import MobileNav from "../MobileNav/MobileNav";
import Typography from "@mui/material/Typography";
import TownSelect from "../TownSelect/TownSelect";
import Joyride, {Step} from "react-joyride";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

export interface State {
    run: boolean;
    steps: Step[];
}

const manageSquadSteps: any = [
    {
        content: <Typography>Обов'язково вкажіть своє місто</Typography>,
        target: '.fstep',
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'left',
        spotlightClicks: true,
    }
]

const Header = () => {
    const townsList = useQuery(`towns`, () => getTownsList(), {staleTime: Infinity})
    const {town} = useTownContext()
    const location = useLocation();
    const [{run, steps}, setState] = useState<State>({
        run: true,
        steps: manageSquadSteps,
    });
    const getLinkStyle = (path: string) => {
        return location.pathname === path ? {color: '#EE2D2E'} : {color: 'white'};
    };

    useEffect(() => {
    if (Object.entries(town).length !== 0)
        setState((prevState) => ({
            ...prevState,
            run: false,
        }));
    }, [town]);
    useEffect(() => {
        saveContextToLocalStorage(town)
    }, [town]);

    if (townsList.isLoading && !townsList.data) return <div>Завантаження...</div>

    return (
        <AppBar position="fixed"
                sx={{
                    marginX: 'auto',
                    backgroundColor: '#0E1513',
                    paddingY: '16px',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                }}
        >
            <Joyride
                continuous
                hideCloseButton
                run={run}
                steps={steps}
                styles={{
                    options: {
                        primaryColor: '#757ce8',
                        textColor: '#fff',
                        zIndex: 1000,
                        backgroundColor: '#EE2D2E',
                    },
                }}
            />
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box display='flex' sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Link to={'/'}>
                            <img className='logo' src={Logo} alt="" style={{marginRight: '50px'}}/>
                        </Link>
                        <Stack display='flex' flexDirection='row' gap={2}>
                            <NavLink className='nav-link' to="/menu" style={() => getLinkStyle('/menu')}>
                                <Typography fontSize='24px' sx={{transition: 'all 0.3s linear', ":hover": {color: '#EE2D2E'}}}>
                                    Меню
                                </Typography>
                            </NavLink>
                            <NavLink className='nav-link' to="/weekly-promotion"
                                     style={() => getLinkStyle('/weekly-promotion')}>
                                <Typography fontSize='24px' sx={{transition: 'all 0.3s linear', ":hover": {color: '#EE2D2E'}}}>
                                    Акція тижня
                                </Typography>
                            </NavLink>
                            <NavLink className='nav-link' to="/happy-hours" style={() => getLinkStyle('/happy-hours')}>
                                <Typography fontSize='24px' sx={{transition: 'all 0.3s linear', ":hover": {color: '#EE2D2E'}}}>
                                    Щасливі години
                                </Typography>
                            </NavLink>
                        </Stack>
                    </Box>
                    <Stack sx={{width: {xs: '100%', md: 'auto'}}} gap={2} flexDirection='row' alignItems='center'>
                        <Box display='flex' alignItems='center'>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}} display='flex' alignItems='center'>
                                <PhoneAndroidIcon sx={{color: '#fff'}} />
                                <Typography >
                                    <a className='phone-number'
                                       href={ //@ts-ignore
                                           `tel:${town?.attributes?.phoneNumber}`}>
                                        {//@ts-ignore
                                            town?.attributes?.phoneNumber}
                                    </a>
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <MobileNav/>
                        </Box>
                        <TownSelect />
                        <Basket/>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;

