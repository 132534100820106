import {PropsWithChildren, ReactNode} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {app} from "../../common/constants";
import {Box} from "@mui/material";


interface PageProps {
    description: string;
    elements?: ReactNode;
    keywords?: string;
    title: string;
}

export const Page = (
    {
        children,
        description,
        elements,
        keywords,
        title,
    }: PropsWithChildren<PageProps>): JSX.Element => (
    <HelmetProvider>
        <Helmet defaultTitle={app.name} titleTemplate={`${app.name} | %s`}>
            <meta name="description" content={description}/>
            {keywords && <meta name="keywords" content={keywords}/>}
            <title>{title}</title>
            {elements}
        </Helmet>
        <Box height={{xs: '90vh', md: '100vh'}}>
            <Box height={{sx: '100%', md: '80%'}}>
                {children}
            </Box>
        </Box>
    </HelmetProvider>
);
