import {Page} from "../../components/Page/Page";
import Box from "@mui/material/Box";
import {useQuery} from "react-query";
import {getWeeklyPromotion} from "../../api/products/getWeeklyPromotion";
import {baseUrl, increaseAndRound} from "../../components/ProductCard/ProductCard";
import Typography from "@mui/material/Typography";
import * as React from "react";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Button from "@mui/material/Button";
import {useBasketContext} from "../../context/BasketContext";
import {Stack} from "@mui/material";
import {useTownContext} from "../../context/TownsContext";

const WeeklyPromotion = () => {
    const {data, isLoading} = useQuery(`weeklyPromotion`, () => getWeeklyPromotion(), {
        staleTime: Infinity
    })
    const {town, setTown} = useTownContext()

    const {setBasket} = useBasketContext();
    const imageUrl = `${baseUrl}${data?.data?.attributes?.img?.data?.attributes.url}`;
    const product = {
        name: data?.data?.attributes?.name,
        description: data?.data?.attributes?.description,
        price: data?.data?.attributes?.price,
        weight: data?.data?.attributes?.weight,
        count: data?.data?.attributes?.count,
        img: data?.data?.attributes?.img,
        quantity: 1
    }
    const addProductToBasket = () => {
        //@ts-ignore
        setBasket((currentBasket) => [...currentBasket, product]);
    };

    if (isLoading && !data) return <div>Завантаження...</div>
    return (
        <Page description="Акція тижня" title="Акція тижня">
            <Box height='100vh' width='100%'
                 display='flex'
                 flexDirection={{xs: 'column', sm: 'row'}}
                 justifyContent='center'
                 alignItems='center' color='#fff'
                 maxWidth='1440px'
                 marginX='auto'
                 textAlign={{xs: 'center', sm: 'left'}}
            >
                <Stack width={{xs: '90%', sm: '50%'}} gap={2} marginX='auto'>
                    <Typography color='#EE2D2E' gutterBottom fontSize={{xs: '30px', md: '52px'}} m='0'>
                        Акція тижня
                    </Typography>
                    <Typography gutterBottom variant="h4" m='0'>
                        {data?.data?.attributes?.name}
                    </Typography>
                    <Typography maxWidth={{xs: '100%', sm: '80%'}}  fontSize={{xs: '14px', sm: '18px'}} variant="body2">
                        {data?.data?.attributes?.description}
                    </Typography>
                    <Typography fontSize='22px'>
                        <span className='weight'>{data?.data?.attributes?.weight} г.</span> <HorizontalRuleIcon
                        sx={{color: '#EE2D2E'}}/> <span className='weight'>{data?.data?.attributes?.count} шт.</span>
                    </Typography>
                    <Typography fontSize='26px'>
                        <span className='weight'>{
                            // @ts-ignore
                            increaseAndRound(data?.data?.attributes?.price, town?.attributes?.discount)},00 грн</span>
                    </Typography>
                    <Button
                        onClick={addProductToBasket}
                        size="medium" variant='contained'
                        sx={{ width: {sx: '100%', sm: '60%'}}}
                    >
                        <Typography fontSize='20px'>
                            В кошик
                        </Typography>
                    </Button>
                </Stack>
                <Box width={{xs: '100%', sm: '45%'}}>
                    <img
                        style={{width: '100%'}}
                        src={imageUrl && imageUrl} alt=""/>
                </Box>
            </Box>
        </Page>
    );
}
export default WeeklyPromotion;