import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";


export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function OrderComplete(props: SimpleDialogProps) {
    const { onClose, open } = props;
    function getRandomNumberBetween10000And30000() {
        const min = 10000;
        const max = 30000;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} sx={{backgroundColor: '№000'}}>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                margin='0 auto'
                textAlign='center'
                height='100%'
                gap={2}
                sx={{backgroundColor: '#000'}}
                p={5}
            >
                <Button sx={{position: 'absolute', right: '5px', top: '0px'}}
                        onClick={() => handleClose()}><ClearIcon/></Button>
                <Typography variant='h3' color='white'>Дякуємо за замовлення!</Typography>
                <Typography fontSize='1.5rem' color='white'>
                    Супер, що ви обрали нас для вашого суші-бенкету! Ваше замовлення <span className='weight'>№{getRandomNumberBetween10000And30000()}</span> вже у процесі
                    приготування, і наші кухарі роблять все можливе, аби зробити його незабутнім.
                </Typography>
                <Typography fontSize='1.5rem' color='white'>
                    Чекайте на дзвінок від нашого оператора найближчим часом, а тим часом розслабтеся та насолоджуйтеся
                    думкою про скору появу смачних суші.
                </Typography>
                <Typography fontSize='1.5rem' color='white'>
                    До скорої зустрічі,
                    Команда Kisomi
                </Typography>
            </Box>
        </Dialog>
    );
}


