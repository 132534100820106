import React from 'react';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';


import {Page} from "../../components/Page/Page";
import Box from "@mui/material/Box";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';

//@ts-ignore
import BannerPhoto from '../../assets/bannerphoto.jpg'
//@ts-ignore
import BannerPhoto2 from '../../assets/bannerphoto2.png'
//@ts-ignore
import BannerPhoto3 from '../../assets/bannerphoto3.png'

import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Home = () => {
    return (
        <Page description="Домашня сторінка" title="Домашня сторінка">
            <Box height='100vh'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Box
                            height='100%'
                            display='flex'
                            flexDirection={{xs: 'column', md: 'row'}}
                            alignItems='center'
                            justifyContent={{xs: 'center', md: 'space-between'}}
                            sx={{
                                paddingTop: {xs: '0', md: '0'},
                                paddingLeft: {xs: '5px', md: '150px'},
                                paddingRight: {xs: '5px', md: '150px'}
                            }}
                        >
                            <Box
                                width={{xs: '100%', md: '45%'}}
                                textAlign={{xs: 'center', md: 'left'}}
                                display='flex'
                                gap={2}
                                alignItems={{xs: 'center', md: 'flex-start'}}
                                flexDirection='column'
                            >
                                <Typography
                                    variant='h2'
                                    color='#EE2D2E'
                                    sx={{fontSize: {xs: '1.5rem', md: '3rem'}}}
                                >
                                    🥡 Доставка суші для справжніх поціновувачів японської кухні
                                </Typography>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.3rem'}}}>
                                    Замовляйте суші для всієї компанії з доставкою по Вашому місту
                                </Typography>
                                <ArrowDownwardIcon/>
                                <Link to={'/menu'}>
                                    <Button variant='contained' sx={{fontSize: '1.2rem'}}>
                                        Перейти до меню
                                    </Button>
                                </Link>
                            </Box>
                            <Box width={{xs: '100%', md: '55%'}}>
                                <img style={{width: '100%', height: '100%'}} src={BannerPhoto} alt=""/>
                            </Box>
                        </Box>
                    </SwiperSlide>

                    <SwiperSlide>
                        <Box
                            height='100%'
                            display='flex'
                            flexDirection={{xs: 'column', md: 'row'}}
                            alignItems='center'
                            justifyContent={{xs: 'center', md: 'space-between'}}
                            sx={{
                                paddingTop: {xs: '0', md: '0'},
                                paddingLeft: {xs: '5px', md: '150px'},
                                paddingRight: {xs: '5px', md: '150px'}
                            }}
                        >
                            <Box
                                width={{xs: '100%', md: '45%'}}
                                textAlign={{xs: 'center', md: 'left'}}
                                display='flex'
                                gap={2}
                                alignItems={{xs: 'center', md: 'flex-start'}}
                                flexDirection='column'
                            >
                                <Typography
                                    variant='h2'
                                    color='#EE2D2E'
                                    sx={{fontSize: {xs: '1.5rem', md: '3rem'}}}
                                >
                                    🎁 Акція тижня
                                </Typography>
                                <Typography
                                    variant='h2'
                                    color='#white'
                                    sx={{fontSize: {xs: '1.5rem', md: '3rem'}}}
                                >
                                    КОЖНОГО ТИЖНЯ МИ ВІДДАЄМО СЕТ МАЙЖЕ НА ШАРУ
                                </Typography>
                                <Typography sx={{fontSize: {xs: '1rem', md: '2rem'}}}>
                                    дізнайтеся, що актуально сьогодні
                                </Typography>
                                <ArrowDownwardIcon/>
                                <Link to={'/weekly-promotion'}>
                                    <Button variant='contained' sx={{fontSize: '1.2rem'}}>
                                        Дізнатися
                                    </Button>
                                </Link>
                            </Box>
                            <Box width={{xs: '100%', md: '55%'}}>
                                <img style={{width: '100%', height: '100%'}} src={BannerPhoto2} alt=""/>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            height='100%'
                            display='flex'
                            flexDirection={{xs: 'column', md: 'row'}}
                            alignItems='center'
                            justifyContent={{xs: 'center', md: 'space-between'}}
                            sx={{
                                paddingTop: {xs: '0', md: '0'},
                                paddingLeft: {xs: '5px', md: '150px'},
                                paddingRight: {xs: '5px', md: '150px'}
                            }}
                        >
                            <Box width={{xs: '100%', md: '55%'}}>
                                <img style={{width: '100%', height: '100%'}} src={BannerPhoto3} alt=""/>
                            </Box>
                            <Box
                                width={{xs: '100%', md: '45%'}}
                                textAlign={{xs: 'center', md: 'left'}}
                                display='flex'
                                gap={2}
                                alignItems={{xs: 'center', md: 'flex-start'}}
                                flexDirection='column'
                            >
                                <Typography
                                    variant='h2'
                                    color='#EE2D2E'
                                    sx={{fontSize: {xs: '1.5rem', md: '3rem'}}}
                                >
                                    🥢 Щасливі години
                                </Typography>
                                <Typography
                                    variant='h2'
                                    color='#white'
                                    sx={{fontSize: {xs: '1rem', md: '1.5rem'}}}
                                >
                                    Акція доступна з понеділка по четвер з 10:00 до 16:00. У вказані дні та години ви зможете замовити роли за вигідною ціною.🔥
                                    Набори ролів змінюються щодня, отож слідкуй!
                                </Typography>
                                <Typography sx={{fontSize: {xs: '1rem', md: '2rem'}}}>
                                    дізнайтеся, що актуально сьогодні
                                </Typography>
                                <ArrowDownwardIcon/>
                                <Link to={'/happy-hours'}>
                                    <Button variant='contained' sx={{fontSize: '1.2rem'}}>
                                        Дізнатися
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
        </Page>
    );
}

export default Home;