// src/contexts/UserContext.tsx
import React, {createContext, useState, useContext, ReactNode} from 'react';
import {ImgUrl} from "../components/ProductCard/ProductCard";

// Визначення типу для даних користувача
export interface ProductType  {
    name: string,
    description: string,
    price: string,
    weight: string,
    pieces: string,
    img: ImgUrl,
    quantity: number;
}

// Визначення типу для контексту
interface BasketContextType {
    basket: ProductType[] | [];
    setBasket: React.Dispatch<React.SetStateAction<ProductType[] | []>>;
}

// Створення контексту з початковим значенням
const BasketContext = createContext<BasketContextType | undefined>(undefined);

// Компонент Provider
interface UserProviderProps {
    children: ReactNode;
}

export const saveContextToLocalStorage = (contextValue: ProductType[]) => {
    localStorage.setItem('basket', JSON.stringify(contextValue));
};

export const getContextFromLocalStorage = (): ProductType[]  => {
    const savedContext = localStorage.getItem('basket');
    return savedContext ? JSON.parse(savedContext) : null;
};

export const BasketProvider: React.FC<UserProviderProps> = ({children}) => {
    const savedContext = getContextFromLocalStorage();
    const [basket, setBasket] = useState<ProductType[] | []>(savedContext || []);

    return (
        <BasketContext.Provider value={{basket, setBasket}}>
            {children}
        </BasketContext.Provider>
    );
};

// Хук для використання контексту
export const useBasketContext = () => {
    const context = useContext(BasketContext);
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export default BasketContext;
