import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useQuery} from "react-query";
import {getTownsList} from "../../api/cities/cities";
import {useTownContext} from "../../context/TownsContext";

const TownSelect = () => {
    const townsList = useQuery(`towns`, () => getTownsList(), {staleTime: Infinity})

    const {town, setTown} = useTownContext()

    if (townsList.isLoading && !townsList.data) return <div>Завантаження...</div>
    return (
        <FormControl fullWidth className='fstep'>
            <InputLabel id="demo-simple-select-label">Ваше місто</InputLabel>
            <Select
                className='town-select'
                labelId="demo-simple-select-label"
                label={'Місто'}
                //@ts-ignore
                defaultValue={town.id}
                onChange={(e) => {
                    const currentTown = townsList.data.data.find((town: any) => town.id === e.target.value)
                    setTown(currentTown)
                }}
                sx={{ minWidth: {xs: '100px', md: '200px'}}}>
                {townsList.data.data && townsList.data?.data.map((town: any) => {
                    return <MenuItem key={town.id} value={town.id}>{town.attributes.nameUK}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}
export default TownSelect;