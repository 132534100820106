//@ts-ignore
import Logo from '../../assets/logo.png'

import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";

const Loader = () => {
    const [show, setShow] = useState(true)

    setTimeout(() => {
      setShow(false)
    }, 2500)

    return (
        show ?
            <Box gap={5} className="loader">
                <img className='logo-loader rotate' src={Logo} alt="" />
                <img className='logo-loader rotateOut' src={Logo} alt="" />
                <img className='logo-loader rotate' src={Logo} alt="" />
                <img className='logo-loader rotateOut' src={Logo} alt="" />
                <img className='logo-loader rotate' src={Logo} alt="" />
                <img className='logo-loader rotateOut' src={Logo} alt="" />
                <img className='logo-loader rotate' src={Logo} alt="" />
                <img className='logo-loader rotateOut' src={Logo} alt="" />
            </Box> : null
    )
}
export default Loader;