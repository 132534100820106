// src/contexts/UserContext.tsx
import React, {createContext, useState, useContext, ReactNode} from 'react';

// Визначення типу для даних користувача
export interface TownType  {
    nameUK: string;
    nameEN: string;
    townID: number;
    discount: number;
    phoneNumber: string;
}


// Визначення типу для контексту
interface TownBasketContext {
    town: TownType | {};
    setTown: React.Dispatch<React.SetStateAction<TownType | {}>>;
}

// Створення контексту з початковим значенням
const TownsContext = createContext<TownBasketContext | undefined>(undefined);

// Компонент Provider
interface UserProviderProps {
    children: ReactNode;
}

export const saveContextToLocalStorage = (contextValue: TownType | {}) => {
    localStorage.setItem('town', JSON.stringify(contextValue));
};

export const getContextFromLocalStorage = (): TownType  => {
    const savedContext = localStorage.getItem('town');
    return savedContext ? JSON.parse(savedContext) : null;
};

export const TownProvider: React.FC<UserProviderProps> = ({children}) => {
    const savedContext = getContextFromLocalStorage();
    const [town, setTown] = useState<TownType | {}>(savedContext || {});

    return (
        <TownsContext.Provider value={{town, setTown}}>
            {children}
        </TownsContext.Provider>
    );
};

// Хук для використання контексту
export const useTownContext = () => {
    const context = useContext(TownsContext);

    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export default TownsContext;
