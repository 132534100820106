import axios from "axios";
import api from "../../axios";

export const getCurrentTown = async () => {
    try {
        const response = await axios.get(`http://ip-api.com/json/?fields=61439`);
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const getTownsList = async () => {
    try {
        const response = await api.get(`/api/towns`);
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};