import * as React from 'react';
import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
//@ts-ignore
import MenuIcon from "@mui/icons-material/Menu";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import {useTownContext} from "../../context/TownsContext";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
// @ts-ignore
import Logo from '../../assets/logo.png';
export default function MobileNav() {
    const [open, setOpen] = React.useState(false);
    const myComponentRef = useRef<HTMLDivElement | null>(null);
    const {town} = useTownContext()

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            //@ts-ignore
            if (myComponentRef.current && !myComponentRef.current?.contains(event.target as Node)) {
                handleDrawerClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myComponentRef]);

    const mobileNavContainer = (anchor: string) => (
        <Box
            ref={myComponentRef}
            sx={{width: {lg: 800}, height: '100vh', backgroundColor: '#000'}}
            py={5}
            role="presentation"
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            onKeyDown={handleDrawerOpen}
        >
            <Box position='absolute' top='10px' right='10px' onClick={handleDrawerClose}>
                <CloseIcon sx={{color: '#fff'}} cursor='pointer'/>
            </Box>
            <Box pl={2}   onClick={handleDrawerClose}>
                <Link to={'/'}>
                    <img className='logo' src={Logo} alt="" style={{marginRight: '50px'}}/>
                </Link>
            </Box>
            <List
                sx={{width: '300px', maxWidth: 360}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Link className='link' to='/menu' onClick={handleDrawerClose}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RestaurantMenuIcon sx={{color: '#fff'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Меню"/>
                    </ListItemButton>
                </Link>
                <Link style={{marginBottom: '50px'}} className='link' to='/weekly-promotion'
                      onClick={handleDrawerClose}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ThumbUpAltIcon sx={{color: '#fff'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Акція тижня"/>
                    </ListItemButton>
                </Link>
                <Link style={{marginBottom: '50px'}} className='link' to='/happy-hours'
                      onClick={handleDrawerClose}>
                    <ListItemButton>
                        <ListItemIcon>
                            <HistoryToggleOffIcon sx={{color: '#fff'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Щасливі години"/>
                    </ListItemButton>
                </Link>
                <ListItemButton >
                    <ListItemIcon>
                        <PhoneAndroidIcon sx={{color: '#fff'}}/>
                    </ListItemIcon>
                    <Typography>
                        <a className='phone-number'
                           href={ //@ts-ignore
                               `tel:${town?.attributes?.phoneNumber}`}>
                            {//@ts-ignore
                                town?.attributes?.phoneNumber}
                        </a>
                    </Typography>
                </ListItemButton>
            </List>


        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <MenuIcon
                    onClick={handleDrawerOpen}
                    sx={{fontSize: '2rem', transition: '0.3s', cursor: 'pointer', ":hover": {color: '#EE2D2E'}}}/>
                <Drawer
                    anchor={"left"}
                    open={open}
                >
                    {mobileNavContainer("left")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}