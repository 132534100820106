import {Page} from "../../components/Page/Page";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CelebrationIcon from '@mui/icons-material/Celebration';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {useBasketContext} from "../../context/BasketContext";
import Button from "@mui/material/Button";
import * as React from "react";

const happyHoursSets = [
    {
        name: 'Акція Понеділка',
        description: 'боніто з тамаго\n' +
            '-блек з сурімі\n' +
            '-філа чіз з лососем',
        price: '300',
        weight: '730',
        count: '24',
        quantity: 1
    }, {
        name: 'Акція Вівторка',
        description: '-сирний рол\n' +
            '-футо з консервованим тунцем та манго\n' +
            '-філа з лососем',
        price: '300',
        weight: '710',
        count: '24',
        quantity: 1
    }, {
        name: 'Акція Середи',
        description: '-філа з лососем\n' +
            '-сирний рол\n' +
            '-блек рол\n',
        price: '300',
        weight: '750',
        count: '24',
        quantity: 1
    }, {
        name: 'Акція Четверга',
        description: `-філа з тунцем, -каліфорнія з консервованим тунцем, -сирний рол з тамаго`,
        price: '300',
        weight: '800',
        count: '24',
        quantity: 1
    },
]

const HappyHours = () => {
    const day = new Date().getDay()
    const currentProduct = happyHoursSets[day - 1]
    const currentDescription = currentProduct?.description.split(', ')
    const {setBasket} = useBasketContext();

    const addProductToBasket = () => {
        //@ts-ignore
        setBasket((currentBasket) => [...currentBasket, currentProduct]);
    };
    return (
        currentProduct ? <Page description="Щасливі години" title="Щасливі години">
            <Box height='100vh' width='100%'
                 display='flex'
                 flexDirection={{xs: 'column', sm: 'column'}}
                 justifyContent='center'
                 alignItems='center' color='#fff'
                 maxWidth='1440px'
                 marginX='auto'
                 textAlign={{xs: 'center', sm: 'left'}}
                 gap={4}
            >
                <Box display='flex' flexDirection='column' gap={4}>
                    <Box display='flex' alignItems='center'>
                        <CelebrationIcon sx={{fontSize: '4rem', color: '#EE2D2E', marginRight: '6px'}}/>
                        <Typography variant='h2'>
                            {currentProduct?.name}
                        </Typography>
                        <CelebrationIcon
                            sx={{fontSize: '4rem', color: '#EE2D2E', transform: 'scaleX(-1)', marginLeft: '6px'}}/>
                    </Box>
                    <Box>
                        {currentDescription.map((item) => {
                            return <Typography fontSize='1.6rem'>{item} </Typography>
                        })}
                    </Box>
                    <Typography fontSize='1.6rem'>
                        <span className='weight'>{currentProduct?.weight} г.</span> <span
                        className='weight'>{currentProduct?.count} г.</span> </Typography>
                    <Button
                        onClick={addProductToBasket}
                        size="medium" variant='contained'
                        sx={{width: {sx: '100%', sm: '60%'}}}
                    >
                        <Typography fontSize='20px'>
                            В кошик
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Page> : <Page description="Щасливі години" title="Щасливі години">
            <Box height='100vh' width='100%'
                 display='flex'
                 flexDirection={{xs: 'column', sm: 'row'}}
                 justifyContent='center'
                 alignItems='center' color='#fff'
                 maxWidth='1440px'
                 marginX='auto'
                 textAlign={{xs: 'center', sm: 'left'}}
            >
                <Typography variant='h2'>
                    Нажаль сьогодні немає акції
                </Typography>
                <SentimentVeryDissatisfiedIcon
                    sx={{fontSize: '5rem', color: '#EE2D2E', transform: 'scaleX(-1)', marginLeft: '12px'}}/>
            </Box>

        </Page>
    )
}

export default HappyHours;
