import {Page} from "../../components/Page/Page";
import Box from "@mui/material/Box";

const Product = () => {

    return (
        <Page description="Cуші" title="Суші">
            <Box height='100vh'>Contacts</Box>
        </Page>
    )

}
export default Product;