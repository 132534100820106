import {Page} from "../../components/Page/Page";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import {Navigation, Pagination, Parallax} from 'swiper/modules';

import {useState} from "react";
import {goNext, goPrev} from "./config";
import MenuPage from "./MenuPage";
import Box from "@mui/material/Box";
import Loader from "../../components/Loader/Loader";

SwiperCore.use([Navigation]);
const MenuItem = {
    0: 'Сети',
    1: 'Запечені',
    2: 'Філа',
    3: 'Маки',
    4: 'Мега',
    5: 'Дракони',
    6: 'Гриль',
    7: 'Kisomi',
    8: 'Футо маки',
    9: 'Спрінг',
    10: 'Роли без рису',
    11: 'Тайська кухня',
    12: 'Каліфорнія',
    13: 'Гункани',
    14: 'Нігірі',
    15: 'Салати'
}
const Menu = () => {
    const [swiper, setSwiper] = useState<SwiperCore>();

    return (
        <Page description="Меню" title="Меню">
            <Loader />
            <Box height='100vh'>
                <Swiper
                    preventInteractionOnTransition={true}
                    pagination={{
                        clickable: true, renderBullet: (index: number, className: string) => {
                            return '<span class="' + className + '">' + (MenuItem[index as keyof typeof MenuItem]) + '</span>';
                        }
                    }}
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        height: '100%',
                    }}
                    speed={600}
                    parallax={true}
                    onSwiper={setSwiper}
                    modules={[Parallax, Pagination, Navigation]}
                    className="swiper-container"
                >
                    <button onClick={() => goPrev(swiper)} className='swiper-nav-btn left-side'><KeyboardArrowLeftIcon
                        sx={{color: '#fff'}}/></button>
                    <button onClick={() => goNext(swiper)} className='swiper-nav-btn right-side'><KeyboardArrowRightIcon
                        sx={{color: '#fff'}}/></button>
                    <div
                        slot="container-start"
                        className="parallax-bg"
                        style={{
                            backgroundImage: ''
                        }}
                        data-swiper-parallax="-23%"
                    ></div>
                    {Object.entries(MenuItem).map((item: any, index) => {
                        return (
                            <SwiperSlide
                                key={item[1]}
                                className='swiper-slide'>
                                <MenuPage id={item[0]} name={item[1]}/>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Box>
        </Page>
    )
}
export default Menu