import {Link, useRouteError} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";

export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <Box color='white' display='flex' flexDirection='column'  justifyContent='center' alignItems='center' height='100vh'>
            <Typography variant='h1'>Ой!</Typography>
            <Typography mb={1} variant='h2'>Сталося щось непередбачуване :(</Typography>
            <ArrowDownwardIcon sx={{marginBottom: '16px'}}/>
            <Link to='/'>
                <Button variant='contained'>
                    Повернутися на головну сторінку
                </Button>
            </Link>
        </Box>
    );
}