import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#EE2D2E',
        },
        secondary: {
            main: '#fff',
        },
        text: {
            secondary: '#EE2D2E'
        }
    },
    typography: {
        fontFamily: [
            'Oswald',
        ].join(','),
    },
    components: {
        // Name of the component
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#EE2D2E',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#000',
                    backgroundColor: '#EE2D2E',

                },
                icon: {
                    color: '#fff',
                },
                select: {
                    backgroundColor: '#EE2D2E',
                    color: '#fff',
                    "&:focus": {
                        backgroundColor: '#EE2D2E',
                    }
                },

            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#EE2D2E',
                    borderRadius: '4px',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    backgroundColor: '#fff',
                    color: '#EE2D2E'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: '#EE2D2E',
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    backgroundColor: '#000',
                }
            }
        }
    },
});