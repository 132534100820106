import {createBrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import Menu from "../pages/Menu/Menu";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import React from "react";
import Root from "../pages/Root/Root";
import WeeklyPromotion from "../pages/WeeklyPromotion/WeeklyPromotion";
import Product from "../pages/Product/Product";
import Home from "../pages/Home/Home.jsx";
import HappyHours from "../pages/HappyHours/HappyHours";

const queryClient = new QueryClient();

export const router = createBrowserRouter([
    {
        path: "/",
        element: <QueryClientProvider client={queryClient}><Root/></QueryClientProvider>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <Home/>,
            }, {
                path: "menu",
                element: <Menu/>,
            }, {
                path: "happy-hours",
                element: <HappyHours/>
            }, {
                path: "weekly-promotion",
                element: <WeeklyPromotion/>,
            }, {
                path: "/menu/:id",
                element: <Product/>
            }]
    },
]);