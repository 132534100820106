import {Outlet} from "react-router-dom";
import Header from "../../components/Header/Header";
import {useEffect} from "react";
import {saveContextToLocalStorage, useBasketContext} from "../../context/BasketContext";

const Root = () => {
    const {basket} = useBasketContext();

    // if(currentTown && townsList?.data?.data.length > 0)
    useEffect(() => {
        saveContextToLocalStorage(basket)
    }, [basket]);

    return (
        <div>
            <Header />
            <Outlet/>
        </div>
    )
}
export default Root;
