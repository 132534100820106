import {Box} from "@mui/material";
import {ReactNode} from "react";

export const ScrollableBox = ({children}: { children: ReactNode }) => {
    return (
        <Box
            gap={3}
            display='flex'
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{
                display: {xs: 'none', md: 'flex'},
                height: '670px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    border: '100px',
                    width: '2px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#888',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#555',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#4D4A43',
                },
                scrollbarWidth: 'thin',
                scrollbarColor: '#888 #f1f1f1',
            }}
        >
            {children}
        </Box>
    );
};