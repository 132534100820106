import api from "../../axios";

export const getProductsByCategory = async (categoryId: number) => {
    try {
        const response = await api.get(`/api/products?populate=*`, {
            params: {
                'filters[category][id]': categoryId,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};