import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider,} from "react-router-dom";
import {ThemeProvider} from '@mui/material';

import {BasketProvider} from "./context/BasketContext";

import {theme} from "./createTheme";
import {router} from "./common/router";
import {TownProvider} from "./context/TownsContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <TownProvider>
            <BasketProvider>
                <ThemeProvider theme={theme}>
                    <RouterProvider router={router}/>
                </ThemeProvider>
            </BasketProvider>
        </TownProvider>
    </React.StrictMode>
);


