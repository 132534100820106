import {Swiper} from "swiper/types";

export const goNext = (swiper: Swiper | undefined) => {
    if (swiper) {
        swiper.slideNext();
    }
};
export const goPrev = (swiper: Swiper | undefined) => {
    if (swiper) {
        swiper.slidePrev();
    }
};